import React, { useState } from "react";
import axios from "axios";
import "./login.css";
import logo from "../../assets/logo-transparent-small.png"; // Certifique-se de que o caminho está correto
import { Eye, EyeOff } from "lucide-react";
import { set } from "date-fns";

const Login = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordVisibility = () => setPasswordVisible(!passwordVisible);

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        "https://mkks4oc.167.99.50.224.sslip.io/login",
        { username, password }
      );
      const token = response.data.token;
      localStorage.setItem("token", token);
      onLoginSuccess(token);
      setMessage("Login bem-sucedido!");
    } catch (error) {
      setMessage(
        "Erro: " + (error.response ? error.response.data : error.message)
      );
    }
  };

  return (
    <div className="wrapper">
      <div className="login-container">
        <div className="login-header">
          <label>Bem vindo!</label>
        </div>
        <div className="login-input-container">
          <label>Login</label>
          <input
            type="text"
            placeholder="Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <div className="passwordArea">
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              className="password-button"
              onClick={handlePasswordVisibility}
            >
              {passwordVisible ? (
                <EyeOff size={24} className="password-icon" />
              ) : (
                <Eye size={24} className="password-icon" />
              )}
            </button>
          </div>
          <button className="login-button" onClick={handleLogin}>
            Entrar
          </button>
          <p>{message}</p>
        </div>
        <div className="login-footer">
          <img src={logo} alt="BSC Logo" />
        </div>
      </div>
    </div>
  );
};

export default Login;
